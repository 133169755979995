<template>
	<ele-div style="position: relative;">
		<div style="margin-bottom: 0px;">
			<filter-type :table.sync="table" @setInfo="getFilterSearch"></filter-type>
		</div>
		<div style="display: flex; margin-top: -20px;">
			<div style="min-height: 100px;width: 100%;">
				<ele-data-table ref="table" :choose.sync="choose" :key="tableKey" :config="table">
					<template slot="table">
						<el-table-column prop="name" label="设备名称">
						</el-table-column>
						<el-table-column prop="corp_name" label="机构名称">
						</el-table-column>
						<el-table-column prop="node_name" label="网点名称">
						</el-table-column>
						<el-table-column prop="address" label="放置详细地址">
						</el-table-column>
						<el-table-column prop="today_amount" label="今日捐款">
							
						</el-table-column>
						<el-table-column prop="amount" label="累计捐款">
							<template slot-scope="scope, index">
								<span style="line-height: 33px;">{{scope.row.amount}}</span>
							</template>
						</el-table-column>
					</template>
				</ele-data-table>
			</div>
		</div>
	</ele-div>
</template>

<script>
	import listToTree from '@/instruction/utils/listToTree.js';
	import FilterType from './components/summary-filter-type.vue'
	export default {
		name: "offer",
		data() {
			return {
				// 表格配置
				table: {
					url: "/api.admin.tran.collect/",
					method: "post",
					where: {
						corp_name: '',//	是	string	机构名称
						node_name: '',//	是	string	网点名称
						name: '',
						page: 1,
					},
					page: true, // 是否开启分页
					tableChecked: false // 是否开启勾选, 开启后 需要加上 饿了么的表格 勾选代码
				},
				// 表格多选
				choose: [],
				tableKey: Math.random(),
			}
		},
		components: {
			FilterType
		},
		mounted() {
			
		},
		methods: {
			getFilterSearch () {
				this.tableKey = Math.random()
				this.$refs.table.reload()
			},
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>
